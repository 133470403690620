<template>
  <div class="class-symbol">
    <van-sticky :z-index="9999">
      <div class="allsymbol-head">
        <NavBar>
          <van-icon
            slot="left"
            color="#fff"
            name="arrow-left"
            @click="$router.push('/quotes')"
          />
          <div class="allsymbol-title" slot="title" style="color: #fff">
            {{ $t("Mt4ApiTrade.addSymbol") }}
          </div>
          
        </NavBar>
      </div>
    </van-sticky>
    <div class="isload-true" v-if="isLoad">
      <van-skeleton title :row="3" />
    </div>
    <div class="isload-false" v-else>
      <van-cell-group>
        <van-cell
        @click="onClasssymbol(item)"
          :title="item"
          v-for="(item, index) in classSymbol"
          :key="index"
        >
          <template #right-icon>
            <van-icon name="arrow" class="arrow" />
          </template>
        </van-cell>
      </van-cell-group>
    </div>
  </div>
</template>

<script>
import { GetSymbols } from "@/api/mt4Api";
import { Toast } from "vant";
import NavBar from "@/components/mt4/NavBar";
export default {
  components: { NavBar },
  data() {
    return {
      items: [],
      active: 0,
      activeIds: [1, 2],
      activeIndex: 0,
      addImg: require("@/assets/image/mt4tabbar/add.png"),
      value: "",
      list: [],
      classSymbol: [],
      iconShow: true,
      flag: false,
      currect: 0,
      existSymbol: [],
      addArr: [],
      records: [],
      isLoad: false,
    };
  },
  computed: {},
  filters: {},
  created() {
    GetSymbols().then(
      (res) =>
        this.list.push(...res.msg.AllSymbol) &&
        this.classSymbol.push(...res.msg.Groups)
    );
  },
  mounted() {
    this.$store.commit("toggleTabbarShow", true);
    this.$store.commit("toggleTabbarCurrentShow", "Mt4");
    const exist = localStorage.getItem("exits");
    this.addArr.push(...JSON.parse(exist));
  },
  methods: {
    onClasssymbol(item) {
        this.$router.replace({
            path: '/quotes/classsymbol',
            query: {
                symbol: item,
            }
        })
    },
    back() {
      this.$router.replace("/quotes");
    },
  
  },
};
</script>

<style>
.class-content {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
}
.allsymbol .van-cell .van-icon-plus {
  color: #13b5b1;
  margin-right: 0.2rem;
}

.van-cell .van-icon-fire {
  color: red;
}

.isShow {
  display: none;
}

.allsymbol-head .van-nav-bar {
  background-color: #13b5b1;
}

.allsymbol .van-image {
  width: 40px !important;
  height: 40px !important;
}

.allsymbol .van-card {
  margin-top: 0;
  padding: 1px;
}

.allsymbol .van-card__thumb {
  width: 1px;
  height: 1px;
  margin-right: 0;
}

.allsymbol .van-card__content {
  min-height: 0;
  height: 0;
}

.allsymbol .van-cell__title {
  display: flex;
  align-items: center;
}

.allsymbol .van-cell {
  padding: 6px 16px;
}
</style>